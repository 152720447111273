@import "../../constants/constants.scss";

.gamification-wrapper {
  display: flex;
  flex-direction: column;
  padding: $header-height 0;
  .gamification__banner-container {
    position: relative;
    .gamification__banner {
      width: 100%;
    }
    .countdown-container {
      position: absolute;
      width: 200px;
      height: 200px;
      background: radial-gradient(77.99% 77.99% at 50% 50%, #ad51ff 0%, #993bab 52.23%, #ec7a88 80.08%, #0e0716 100%);
      border: 1.19074px solid #d23ab5;
      box-sizing: border-box;
      box-shadow: -2.38148px 0px 39.2945px 23.8148px rgb(143 58 244 / 60%);
      border-radius: 1000px;
      right: 50px;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white;
      font-family: SegoeUI-bold;
      &.live {
        border: 0.658182px solid #b8ffc3;
        box-shadow: -1.31636px 0px 21.72px 13.1636px #96ff96;
        background: radial-gradient(
          77.99% 77.99% at 50% 50%,
          #00ff00 0%,
          #52e052 52.23%,
          #fcffd7 80.08%,
          #0e0716 100%,
          #004400 100%
        );
        color: $blue-6;
      }
      .gamification__countdown {
        margin: 5px 0;
      }
    }
  }
  .gamification-content {
    padding: $element-padding;
    section {
      display: flex;
      flex-direction: column;
      margin: 50px 0;
      white-space: pre-line;
      color: $white;
      .title {
        font-size: $medium;
        color: $white;
        margin-bottom: 10px;
        font-family: SegoeUI-bold;
      }
      .sub-title {
        font-weight: bold;
        margin-bottom: 10px;
        text-decoration: underline;
      }
    }
    .gamification-footer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      max-width: $element-max-width;
      margin-top: 50px;
      justify-content: flex-end;
      a {
        color: $turquoise;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .gamification-wrapper {
    padding-bottom: 0;
    .gamification__banner-container {
      .countdown-container {
        width: 100px;
        height: 100px;
        bottom: 20px;
        right: 20px;
        font-size: $xxxsmall;
      }
    }
    .gamification-content {
      padding: $mobile-element-padding;
      section {
        margin: 25px 0;
      }
      .gamification-footer-wrapper {
        flex-direction: column;
        align-items: center;
        a {
          margin-bottom: 50px;
        }
      }
    }
  }
}
