@import "../../../../constants/constants.scss";

.for-artists-wrapper {
  display: flex;
  max-width: $element-max-width;
  margin: auto;
  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 150px);
    overflow: scroll;
  }
  iframe {
    width: 100%;
    margin-left: 60px;
    max-height: 1000px;
    border: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .for-artists-wrapper {
    flex-direction: column;
    height: unset;
    .info-wrapper {
      height: unset;
    }
    iframe {
      margin-left: unset;
      height: 50vh;
    }
  }
}
