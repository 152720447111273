@import "../../../../constants/constants.scss";

.embassy-collection-wrapper {
  display: flex;
  flex-direction: column;
  max-width: $element-max-width;
  margin: auto;
  .embassy-collection__to-airdrop-machine-btn {
    margin: 50px 0;
    width: 350px;
    height: 40px;
    border-radius: 50px;
    align-self: center;
    background-color: $green-1;
    color: $deep-blue;
    font-weight: bold;
    border: none;
  }
  .embassy-collection__nfts-container {
    display: grid;
    grid-gap: 73px;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
    margin-top: 20px;
  }
}
