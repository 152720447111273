@import "../../constants/constants.scss";

.landing-wrapper {
  padding-top: $header-height;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  .section {
    margin: 100px 0;
    width: 100%;
    .section-content {
      display: flex;
      max-width: $element-max-width;
      padding: 0 $element-padding;
      margin: auto;
      align-items: center;
      .section-text-wrapper {
        display: flex;
        flex-direction: column;
        color: $white;
        .section-text-header-wrapper {
          display: flex;
          align-items: flex-end;
          font-weight: bold;
          margin-bottom: 15px;
          font-size: $medium;
          .section-header-icon {
            margin-right: 10px;
          }
        }
        .section-title {
          font-size: $xxlarge;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .section-description {
          font-size: $large;
          white-space: pre-line;
        }
      }
    }
  }
  .section:first-child {
    margin-top: unset;
  }

  .title {
    color: $white;
    font-size: $medium;
    margin-bottom: 30px;
    &.large {
      font-size: $large;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .landing-wrapper {
    .section {
      margin: 50px 0;
      .section-content {
        flex-direction: column-reverse;
        padding: 0 $mobile-element-padding;
        .section-text-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 35px;
          .section-text-header-wrapper {
            font-size: $xsmall;
          }
          .section-title {
            font-size: $large;
            font-weight: 700;
          }
          .section-description {
            font-size: $small;
          }
        }
        .section-image {
          width: 100%;
        }
      }
    }
  }
}
