@import "../../constants/constants.scss";

.footer-wrapper {
  width: 100%;
  padding: 0 $element-padding;
  height: $footer-height;
  box-sizing: border-box;
  margin-top: 150px;
  .footer-content {
    max-width: $element-max-width;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .links-wrapper {
      display: flex;
      align-items: center;
      a {
        margin: 0 25px;
        text-decoration: none;
        color: $turquoise;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .footer-wrapper {
    height: unset;
    margin-bottom: 50px;
    .footer-content {
      flex-direction: column-reverse;
      .links-wrapper {
        margin: 10px 0;
        flex-direction: column;
        a {
          margin: 5px 0;
        }
      }
    }
  }
}
