@import "../../../../constants/constants.scss";

.collections-wrapper {
  display: flex;
  flex-direction: column;
  max-width: $element-max-width;
  margin: auto;
  .nfts-media-container {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(auto-fit, 250px);
    justify-content: center;
    .nft-media-wrapper {
      display: flex;
      flex-direction: column;
      background-color: $blue;
      video {
        width: 250px;
      }
      .details-wrapper {
        display: flex;
        flex-direction: column;
        color: $white;
        margin: 12px 12px 50px 12px;
        .item-name {
          margin-top: 20px;
        }
      }
    }
  }
}
