@import "../../../../constants/constants.scss";

.welcome-wrapper {
  width: 100%;
  .hats-finance {
    color: $deep-turquoise;
    font-size: $xlarge;
    font-weight: 700;
  }
  .start {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    display: block;
    margin-top: 100px;
    margin-bottom: 60px;
    width: 345px;
    height: 50px;
    font-size: $medium;
    img {
      margin-left: 5px;
    }
  }
  .moto {
    font-size: $large;
    font-style: italic;
    color: $white;
    max-width: $element-max-width;
    margin: auto;
    text-align: center;
    margin-top: 100px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .welcome-wrapper {
    flex-direction: column;
    .hats-finance {
      font-size: $large;
    }
    .start {
      width: calc(100% - 40px);
      font-size: $small;
      margin-top: 50px;
    }
    .moto {
      font-size: $small;
      margin-top: 40px;
    }
  }
}
