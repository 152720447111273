@import "../../constants/constants.scss";

.nft-wrapper {
  padding: $header-height 0;
  .banner {
    width: 100%;
  }
  .nft-content {
    padding: $element-padding;
    max-width: 700px;
    margin: auto;
    section {
      display: flex;
      flex-direction: column;
      margin: 50px 0;
      white-space: pre-line;
      color: $white;
      .title {
        font-size: $medium;
        color: $white;
        margin-bottom: 10px;
      }
    }
    .nft-footer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      max-width: $element-max-width;
      margin-top: 50px;
      a {
        color: $turquoise;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .nft-wrapper {
    padding-bottom: 0;
    .nft-content {
      padding: $mobile-element-padding;
      section {
        margin: 25px 0;
      }
      .nft-footer-wrapper {
        flex-direction: column;
        align-items: center;
        a {
          margin-bottom: 50px;
        }
      }
    }
  }
}
