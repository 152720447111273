
/* colors */
$deep-blue: #1A0163;
$deep-blue-2: #1A0B4D;
$dark-blue: #000723;
$blue: #0E162B;
$blue-2: #6472AF;
$blue-3: #1C2C74;
$blue-4: #1F0E72;
$blue-5: #15093F;
$blue-6: #170453;
$turquoise: #8AFCFD;
$turquoise-2: #C0F2F2;
$turquoise-3: #9BC8CA;
$deep-turquoise: #06E6E6;
$white: #FFFFFF;
$black: #000000;
$blue-gradient: #1A0064;
$black-gradient: #0E0E18;
$green-1: #8AFF00;
$red-1:#C82868;

/* font sizes */
$xxlarge: 48px;
$xlarge: 32px;
$large: 24px;
$medium: 18px;
$small: 16px;
$xsmall: 14px;
$xxsmall: 12px;
$xxxsmall: 10px;
$tiny: 8px;

/* others */
$mobile-breakpoint: 770px;
$small-screen-breakpoint: 1440px;
$element-max-width: 1220px;
$element-padding: 50px;
$mobile-element-padding: 20px;
$header-height: 100px;
$footer-height: 100px;
