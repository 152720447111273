@import "./constants/constants.scss";

@font-face {
  font-family: SegoeUI;
  src: url(./assets/fonts/Segoe-UI.ttf);
}

@font-face {
  font-family: SegoeUI-bold;
  src: url(./assets/fonts/Segoe-UI-bold.ttf);
}

html {
  background-color: $deep-blue;
  -webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}

body {
  font-family: SegoeUI;
  background: linear-gradient(180deg, $blue-gradient 0%, $black-gradient 100%);
  margin: 0px;
}

#document {
  iframe {
    display: none;
  }
}
