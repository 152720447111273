@import "../../../../constants/constants.scss";

.challenges-wrapper {
  display: flex;
  flex-direction: column;
  max-width: $element-max-width;
  margin: auto;
  max-width: 700px;
  .challenges__select {
    color: $white;
  }
  .ctf-2-wrapper {
    display: flex;
    flex-direction: column;
    .ctf-2__faq-link {
      margin-top: 30px;
      font-family: SegoeUI-bold;
    }
    .ctf-2-button {
      margin: auto;
      width: 420px;
      height: 40px;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .challenges-wrapper {
    .ctf-2-wrapper {
      .ctf-2-button {
        width: 100%;
      }
    }
  }
}
