@import "../../../constants/constants.scss";

.countdown-wrapper {
  display: flex;
  .time-element {
    color: inherit;
    .value {
      font-size: $xlarge;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .countdown-wrapper {
    .time-element {
      .value {
        font-size: $xxxsmall;
      }
    }
  }
}
