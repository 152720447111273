@import "../../constants/constants.scss";

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 2;
  width: 100%;
  padding: 20px $element-padding;
  box-sizing: border-box;
  height: $header-height;
  background-color: $deep-blue;
  .links-wrapper {
    display: flex;
    justify-content: space-between;
    .create-vault-btn {
      margin: 0px 30px;
    }
    a {
      margin: 0 25px;
      line-height: 30px;
      text-decoration: none;
      color: $white;
      &.selected {
        font-weight: bold;
        border-bottom: 3px solid;
      }
    }
    .links__nft {
      position: relative;
      .links__nft-new {
        position: absolute;
        top: -15px;
        color: $white;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: $xxxsmall;
        .links__dot {
          border-radius: 10px;
          width: 10px;
          height: 10px;
          background-color: $red-1;
          margin-right: 2px;
        }
      }
    }
  }

  .menu-wrapper {
    position: fixed;
    z-index: 3;
    background-color: $blue;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border: 1px solid;
    box-sizing: border-box;
    justify-content: center;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .header-wrapper {
    padding: 20px $mobile-element-padding;
    img {
      z-index: 4;
    }
    .links-wrapper {
      flex-direction: column;
      align-items: center;
      a,
      button {
        margin: 10px 0;
      }
      .create-vault-btn {
        margin: 10px 0;
      }
    }
  }
}
