@import "../../../../constants/constants.scss";

.projects-section {
  background: linear-gradient(90deg, #10052f 0.18%, rgba(22, 23, 43, 0) 99.83%, rgba(22, 23, 43, 0) 99.83%);
  padding: 60px 0px;

  .projects-wrapper {
    display: flex;
    flex-direction: column;

    .projects-title {
      align-self: flex-start;
      color: $white;
      font-size: $xlarge;
      margin-bottom: 30px;
    }

    .projects-elements-container {
      width: 100vw;
      overflow-x: hidden;

      .projects-elements-container__slider {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 65px;
        animation: scroll 45s linear infinite;

        .project-element-wrapper {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          color: $white;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          .project-symbol {
            width: 60px;
            height: 60px;
            margin-bottom: 15px;
          }
        }
      }

      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(calc(-125px * 22));
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .projects-section {
    padding: 50px 0px;

    .projects-wrapper {
      flex-direction: column !important;

      .projects-title {
        align-self: unset;
      }

      .projects-elements-container {
        grid-template-columns: repeat(auto-fit, 50px);
        grid-gap: 60px;

        .project-element-wrapper {
          .project-symbol {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}
