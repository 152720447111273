@import "../../../../constants/constants.scss";

.the-staker-wrapper {
  .stake {
    height: 50px;
    width: 180px;
    align-self: flex-end;
    margin-top: 50px;
    font-size: $medium;
    margin-right: 50px;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .the-staker-wrapper {
    .stake {
      width: 100%;
      font-size: $small;
      margin-right: unset;
    }
  }
}
