@import "../../constants/constants.scss";

.social-links-wrapper {
  img {
    margin-right: 25px;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  img:last-child {
    margin-right: unset;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .social-links-wrapper {
    img {
      margin-right: unset;
    }
  }
}
